<template>
    <div class="my">
      <!-- <h1>测试题页面</h1>
      <el-button @click="$router.push('/')">返回首页</el-button> -->

      <div class="lfFull">
        <div class="tip">你的专属MBTI测试报告已生成</div>
        <div class="time">生成于： 2024-09-03  11:54:53</div>
        <div class="full_dv">
          <div class="one">
            <div class="one_a">
              <div>你的测试类型# </div>
              <div>INTJ</div>
              <div>建筑师，策划者</div>
            </div>
            <div class="one_b">
              <img src="../../assets//images/normal_u139.png" alt="">
            </div>
            <div class="one_c">
              <img src="../../assets//images/normal_u135.png" alt="">
            </div>
          </div>


          <div class="navs" style="height: 4px;"></div>
          <div class="navs" style="height: 7px;"></div>
          <div class="two">
            <img src="../../assets/images/normal_u142.png" alt="">
          </div>
          <div class="picTextA">
            <img src="../../assets/images/normal_u140.png" alt="">
          </div>
          <div class="picTextB">
            <img src="../../assets/images/normal_u141.png" alt="">
          </div>

          <div class="list">
            <div class="lsText">
              <div>MBTI</div>
              <div>这四个字母代表什么</div>
            </div>
            <img class="list_pca" src="../../assets/images/normal_u143.png" alt="">
            <img class="list_pcb" src="../../assets/images/normal_u144.png" alt="">
            <img class="list_pcc" src="../../assets/images/normal_u145.png" alt="">
            <img class="list_pcd" src="../../assets/images/normal_u146.png" alt="">
          </div>

          <div class="popu">
            <div class="tit">人口比例</div>
            <div>Proportion of population</div>
            <img class="popu_pca" src="../../assets/images/normal_u148.png" alt="">
            <img class="popu_pcb" src="../../assets/images/normal_u147.png" alt="">
          </div>

          <div class="popu" style="color:#ea8f6b;">
            <div class="tit">价值观和动机</div>
            <div>Values and motivations</div>
            <img class="popu_pcc" src="../../assets/images/normal_u149.png" alt="">
          </div>
        </div>
      </div>
      <div class="rtFull">
        <div class="title">付费即可解锁专属于您的<a style="color: red;">完整报告</a></div>
        <div class="profit">
					<div class="price">
						<span>¥ 9.9</span>
						<span>¥ 59.9</span>
					</div>
					<div class="assay">完整解读+职业推荐+个人成长+恋爱分析</div>
				</div>

        <div class="code">
          <div class="pay">
            <img src="./../../assets/images/normal_u88.png" alt="">
            微信支付
            <span class="line"></span>
          </div>
          <div class="scan">
            <img src="../../assets/images/normal_u92.png" alt="">
          </div>
          <div class="tip">
            <img src="./../../assets/images/normal_u88.png" alt="">
            微信APP扫码支付
          </div>
        </div>

        <div class="waive">
          <img src="../../assets/images/normal_u97.png" alt="">
          放弃支付并退出
        </div>

        <div class="cut">
          <div class="hint">付费解锁后，您可以享受以下权利</div>
          <li>
            <img src="../../assets/images/normal_u80.png" alt="">
            <span>获取您的4字母类型测试结果</span>
          </li>
          <li>
            <img src="../../assets/images/normal_u76.png" alt="">
            <span>发现适合您性格类型的职业</span>
          </li>
          <li>
            <img src="../../assets/images/normal_u77.png" alt="">
            <span>知悉您的偏好优势和类型描述</span>
          </li>
          <li>
            <img src="../../assets/images/normal_u78.png" alt="">
            <span>了解您的成长性格和成长描述</span>
          </li>
          <li>
            <img src="../../assets/images/normal_u79.png" alt="">
            <span>评估您与恋人的长期相处情况</span>
          </li>
        </div>

        <div class="sign">所有内容基于卡尔·荣格（Carl Jung）和伊莎贝尔·布里格斯·迈尔斯（Isabel Briggs Myers）的MBTI理论实证</div>
      </div>

    </div>
  </template>
  
  <script>
  export default {
    name: 'Test',
  }
  </script>
  <style lang="less" scoped>
  .my{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 114px;
    box-sizing: border-box;
    margin-top: 42px;
    .lfFull{
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .tip{
        color: #333333;
        font-size: 42px;
      }
      .time{
        color:#d7d7d7;
        font-size: 26px;
        margin-top: 21px;
      }
      .full_dv{
        width: 920px;
        margin-top: 20px;
      }
    }
    .rtFull{
      flex: 0 0 auto;
      width: 461px;
      height: 996px;
      padding: 12px 17px;
      border-radius: 18px;
      border: 1px solid #d7d7d7;
      background-color: #ffffff;
      box-sizing: border-box;
      position: relative;
    }
  }
  .full_dv{ 
    img{
      width: 100%;
      height:100%;
      background-size: cover;
    }
    .one{
      width: 100%;
      height: 321px;
      background-image: url('../../assets/images/normal_u134.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      padding: 0 52px 0 27px;
      box-sizing: border-box;

      display: flex;
      justify-content: space-between;
      align-items: center;
      .one_a{
        color: #533684;
        font-size: 22px;
        
        div:nth-child(2){
          font-size: 88px;
          font-weight: 800;
          height: 118px;
          margin: 33px 0 24px 0;
        }
      }
      .one_b{
        width: 214px;
        height: 268px;
      }
      .one_c{
        width: 346px;
        height: 134px;
      }
    }
  }
  .navs{
    width: 100%;
    background-image: linear-gradient(90deg, #fefcfe 1.13%, #8664d1 49%, #f3edf8 99%);
    margin-top: 6px;
  }
  .two{
    width: 920px;
    height: 69px;
    border: 1px solid #f7c7b4;
    background-color: #fcefe9;
    margin: auto;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 341px;
      height: 48px;
 
    }
  }
  .picTextA{
    width: 100%;
    height: 182px;
    margin-top: 38px;
  }
  .picTextB{
    width: 100%;
    height: 260px;
    margin-top: 26px;
  }
  .list{
    margin-top: 38px;
    list-style: none;
    .lsText{
      div:first-child{
        font-size: 42px;
        color: #ea8f6b;
        font-family: "ToppanBunkyuMidashiGothicStdN-ExtraBold", "Toppan Bunkyu Midashi Gothic Extrabold", "Toppan Bunkyu Midashi Gothic", sans-serif;
        font-weight: 800;
      }
      div:nth-child(2){
        color: #6030c6;
        font-size: 18px;
        margin-top: -18px;
      }
    }
    img{
      display: block;
      margin:0;padding:0;
      margin-top: 30px;
    }
    .list_pca{
      width: 380px;
      height: 45px;
    }
    .list_pcb{
      width: 614px;
      height: 50px;
    }
    .list_pcc{
      width: 539px;
      height: 41px;
      margin-top: 17px;
    }
    .list_pcd{
      width: 707px;
      height: 44px;
    }
  }
  .popu{
    color: #6030c6;
    font-size: 12px;
    margin-top: 39px;
    .tit{
      font-size: 42px;
      font-weight: 800;
    }
    img{
      display: block;
      margin:0;padding:0;
      margin-top: 12px;
    }
    .popu_pca{
      width: 100%;
      height: 69px;
    }
    .popu_pcb{
      width: 546px;
      height: 170px;
    }
    .popu_pcc{
      width: 880px;
      height: 151px;
    }
  }
  .rtFull{
    .title{
      height: 30px;
      font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
      color: #333333;
      text-align: center;
      font-size: 22px;
    }
    .profit{
      width: 100%;
      height: 137px;
      border-radius: 10px;
      border: 1px solid #d7d7d7;
      box-sizing: border-box;
      margin-top: 36px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #7f7f7f;
      .price{
        span:first-child{
          font-size: 56px;
          color: #333333;
          font-family: "PingFangSC-Medium", "PingFang SC Medium", "PingFang SC", sans-serif;
          font-weight: 500;
        }
        span:nth-child(2){
          font-family: "PingFangSC-Regular", "PingFang SC", sans-serif;
          font-size: 18px;
          margin-left: 30px;
					text-decoration: line-through;
        }
      }
      .assay{
        font-size: 14px;
        padding-bottom: 9px;
        border-bottom: 1px solid #d7d7d7;
      }
    }
    .code{
      margin-top: 20px;
      width: 100%;
      height: 351px;
      padding: 0 29px;
      border-radius: 10px;
      border: 1px solid #d7d7d7;
      box-sizing: border-box;
      .pay{
        width: 100%;
        height: 26px;
        margin-top: 18px;
        border-bottom: 3px solid #d7d7d7;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 9px;
        font-size: 20px;
        color:#333333;
        position: relative;
        
        img{
          width: 26px;
          height: 26px;
          margin-right: 20px;
        }
        .line{
          position: absolute;
          width: 228px;
          height: 2px;
          border: 2px solid #4cbf00;
          background-color: #4cbf00;
          box-sizing: border-box;
          bottom: -2px;
        }
      }
      .scan{
        width: 242px;
        height: 242px;
        margin: auto;
        margin-top: 17px;
        border: 1px solid #d7d7d7;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 215px;
          height: 215px;
        }
      }
      .tip{
        width: 100%;
        height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        margin-top: 8px;
        img{
          width: 17px;
          height: 17px;
          margin-right: 6px;
        }
      }
    }
    .waive{
      margin-top: 20px;
      width: 100%;
      height: 42px;
      border-radius: 10px;
      border: 1px solid #9a999c;
      background-color: #9a999c;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 16px;
      img{
        width: 22px;
        height: 22px;
        margin-right: 6px;
      }
    }

    .cut{
      list-style: none;
      .hint{
        font-size: 18px;
        color: #ec808d;
        text-align: center;
        margin-top: 23px;
      }
      li{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 21px;
        font-size: 16px;
        img{
          width: 26px;
          height: 26px;
          margin-right: 12px;
        }
        span{
          width: 50%;
        }
      }
    }
    .sign{
      font-family: "Arial Bold", "Arial", sans-serif;
      font-weight: 700;
      color: #aaaaaa;
      font-size: 6px;
      text-align: center;
      // position: absolute;
      // bottom: 12px;
      margin-top: 40px;
      
    }
  }
  
  </style>
  

<template>
    <div class="home">
      <img class="backdrop" src="../../assets/images/normal_u0.png" alt="">
      <div class="contnent">
        <div class="logo">
          <img src="../../assets/images/normal_u25.png"></img>
        </div>
        <div class="title">MBTI</div>
        <div class="sign">
          <img src="../../assets/images/paixu.png" alt="">
        </div>
        <div class="pattern">
          <div class="patImg" style="transform: rotate(180deg);">
            <img src="../../assets/images/normal_u21.png"></img>
          </div>
          <div style="margin: 0 22px;">16种性格类型</div>
          <div class="patImg" style="transform: rotate(330deg)">
            <img src="../../assets/images/normal_u21.png"></img>
          </div>
        </div>
        <div class="visualBox swiper-container">
          <div class="swiper-wrapper" style="display: flex;align-items: center;">
            <div class="swiper-slide swiper-slide-center none-effect frame" 
                v-for="item in swiperList" :key="item.id"
                :style="{'background':item.bgColor,'box-shadow':'2px 2px 5px 0px'+item.bgColor ,'border-color':item.bgColor} ">
              <!-- <h3>{{ item.id }}</h3>  -->
              <h3>{{item.short}}</h3>
              <h3>{{item.title}}</h3>
            </div>
          </div>
        </div>
        <div class="ingress" @click="$router.push('/Test')">开始测试</div>
      </div>
    </div>
  </template>
  
  <script>
  import Swiper from 'swiper'
  import 'swiper/css/swiper.min.css' // 注意这里的引入
  export default {
    name: 'Home',
		components: {},
		data() {
			return {
        swiper: null,
        swiperList: [{
          id: 1,
          short: 'ENFJ',
          title: '主人公',
          imgUrl: require("@/assets/img/1.png"),
          bgColor:'#57daf1'
        }, {
          id: 2,
          short: 'INFT',
          title: '提倡者',
          bgColor:'#f1b0d8',
          imgUrl: require("@/assets/img/2.png"),
        }, {
          id: 3,
          short: 'ENFP',
          title: '竞选者',
          bgColor:'#9347fd',
          imgUrl: require("@/assets/img/3.png"),
        }, {
          id: 4,
          short: 'INFP',
          title: '调停者',
          bgColor:'#333952',
          imgUrl: require("@/assets/img/5.png"),
        }, {
          id: 5,
          short: 'ENTJ',
          title: '指挥官',
          bgColor:'#adf728',
          imgUrl: require("@/assets/img/7.png"),
        }, {
          id: 6,
          short: 'INTJ',
          title: '建筑师',
          bgColor:'#fd8600',
          imgUrl: require("@/assets/img/8.png"),
        }, {
          id: 7,
          short: 'ENTP',
          title: '辩论家',
          bgColor:'#00a4ca',
          imgUrl: require("@/assets/img/6.png"),
        }, {
          id: 8,
          short: 'INTP',
          title: '逻辑学家',
          bgColor:'#2529a4',
          imgUrl: require("@/assets/img/4.png"),
        },
        {
          id: 9,
          short: 'ESTJ',
          title: '总经理',
          bgColor:'#bb934c',
          imgUrl: require("@/assets/img/5.png"),
        }, {
          id: 10,
          short: 'ISTJ',
          title: '物流师',
          bgColor:'#201c5d',
          imgUrl: require("@/assets/img/7.png"),
        }, {
          id: 11,
          short: 'ESFJ',
          title: '执政官',
          bgColor:'#9661d1',
          imgUrl: require("@/assets/img/8.png"),
        }, {
          id: 12,
          short: 'ISFJ',
          title: '守卫者',
          bgColor:'#00b079',
          imgUrl: require("@/assets/img/6.png"),
        },
        {
          id: 13,
          short: 'ESTP',
          title: '企业家',
          bgColor:'#193486',
          imgUrl: require("@/assets/img/5.png"),
        }, {
          id: 14,
          short: 'ISTP',
          title: '鉴赏家',
          bgColor:'#00ad4b',
          imgUrl: require("@/assets/img/7.png"),
        }, {
          id: 15,
          short: 'ESFP',
          title: '表演者',
          bgColor:'#487a13',
          imgUrl: require("@/assets/img/8.png"),
        }, {
          id: 16,
          short: 'ISFP',
          title: '探险家',
          bgColor:'#005253',
          imgUrl: require("@/assets/img/6.png"),
        }]
			}
		},
		created() {},
		mounted() {
      this.Swiper()
    },
    methods: {
      Swiper() {
          this.swiper = new Swiper('.swiper-container', {
                slidesPerView: 4,
                spaceBetween: 0,
                loop: false, // 无缝
                speed: 600,
                autoplay: true,
                autoplayDisableOnInteraction: false,
                disableOnInteraction: true,
                observer: true,
                observeParents: true,
                pagination: '.swiper-pagination',
                paginationClickable: true,
                mousewheelControl: true,
                debugger: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            });
        },
    }
	}
  </script>
  <style lang="less" scoped>
  // @import '../../components/mobile.css';
  @import '../../components/web.css';
  .swiper-container {
    background: #ffffff !important;
    padding: 10px 0;
  }

  .swiper-slide {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    width: 200px !important;
    height: 245px !important;

    .frame:nth-child(1) {
      margin-left: 19px;
    }
  }
</style>


import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);


import Home from '@/views/trial/home.vue';
import Test from '@/views/trial/tests.vue';
const routes = [
    { path: '/', component: Home },
    { path: '/Test', component: Test }
  ]
  
export default new VueRouter({ routes })
